interface EntityWithAvatar {
	id: string;
	avatar: string | null;
}

export const mountUserAvatarSrc = (avatarEntity: Partial<EntityWithAvatar>) => {
	if (avatarEntity.id && avatarEntity.avatar) {
		return `/api/user/${avatarEntity.id}/avatar`;
	}
	return '';
};

export const mountAwardAvatarSrc = (awardEntity: Partial<EntityWithAvatar>) => {
	if (awardEntity.id && awardEntity.avatar) {
		return `/api/award/${awardEntity.id}/avatar`;
	}
	return '';
};
